import React, { Suspense } from "react";
import { Spin } from "antd";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Dashboard from "./dashboard";
import withAdminLayout from "../../layout/withAdminLayout";
import journals from "../../container/journals/journals";
import seasons from "../../container/seasons/seasons";
import poems from "../../container/poems/poems";
import artWork from "../../container/artWork/artWork";
import meditations from "../../container/meditations/meditations";
import users from "container/user/users";
import isbn from "container/isdn/isdn";
import policy from "container/staticpage/Policy";
import terms from "../../container/staticpage/terms";
import devotional from "container/staticpage/Devotion";
import about from "container/staticpage/About";
import share from "container/staticpage/Howtoshare";
import GiftStore from "container/giftStore/GiftStore";

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={path} title={"TestingDashboard"} component={Dashboard} />
        <Route exact path={`${path}/poems`} component={poems} />
        <Route
          path={`${path}/poems/:id`}
          title={"journals"}
          component={journals}
        />
        <Route path={`${path}/isbn`} component={isbn} />
        <Route path={`${path}/seasons`} component={seasons} />
        <Route path={`${path}/users`} component={users} />
        <Route path={`${path}/artwork`} component={artWork} />
        <Route path={`${path}/meditations`} component={meditations} />
        <Route path={`${path}/policy`} component={policy} />
        <Route path={`${path}/devotional`} component={devotional} />
        <Route path={`${path}/terms`} component={terms} />
        <Route path={`${path}/about`} component={about} />
        <Route path={`${path}/share`} component={share} />
        <Route path={`${path}/store`} component={GiftStore} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
