import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Modal, Table, Popconfirm } from "antd";
import { Main } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Button } from "../../components/buttons/buttons";
import {
  journalsAdd,
  getJournals,
  deleteLists,
  journalsUpdate,
} from "../../redux/journals/actionCreator";
import useStateRef from "react-usestateref";

import { useDispatch } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { useLocation } from "react-router-dom";
const Journals = () => {
  let location = useLocation();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [journalsList, setJournalsList] = useState([]);
  console.log("journalsList", journalsList);

  const [updateId, setUpdateId, setUpdateIdRef] = useStateRef("");

  const dispatch = useDispatch();

  const [defaultPageSize, setDefaultPageSize] = useState(10);

  useEffect(() => {
    onPageChange(1, 10);
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setUpdateId("");
  };
  console.log("pagis", journalsList);

  //CREATE API
  const onSubmit = async (value) => {
    value.type = "text";
    value.seasonsId = location && location.state && location.state.seasonsId;
    value.poemId = location && location.state && location.state.poemId;

    if (setUpdateIdRef.current !== "") {
      let Update = await dispatch(
        journalsUpdate(setUpdateIdRef.current, value)
      );
      if (Update) {
        onPageChange(1, 10);
        setIsModalOpen(false);
        setUpdateId("");
      }
    } else {
      let create = await dispatch(journalsAdd(value));
      console.log("create", value);
      if (create) {
        onPageChange(1, 10);
        setIsModalOpen(false);
      }
    }
    form.resetFields();
  };

  //DELETE API
  const journalsDelete = async (id) => {
    const deleted = await dispatch(deleteLists(id));
    if (deleted) {
      onPageChange(1, 10);
    }
  };

  //PAGE SIZE SET FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setDefaultPageSize(pageSizes);
    let list = await dispatch(
      getJournals(page, pageSizes, location.state.poemId)
    );
    if (list) {
      setJournalsList(list);
    }
  };
  //EDIT API
  const editSeasons = (value) => {
    setUpdateId(value._id);
    setIsModalOpen(true);
    console.log("🚀 ~ file: seasons.js ~ line 66 ~ editSeasons ~ abc", value);
    form.setFieldsValue(value);
  };

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      render: (value) => {
        return <span>{value && value ? value : "-"}</span>;
      },
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (value, record) => {
        return (
          <>
            <a
              className="btn-icon"
              onClick={() => editSeasons(record)}
              to="#"
              shape="circle"
            >
              <FeatherIcon icon="edit" size={16} />
            </a>

            <Popconfirm
              title="Are you sure to delete this journal?"
              onConfirm={() => journalsDelete(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">
                {" "}
                <FeatherIcon size={14} icon="trash-2" />
              </a>
            </Popconfirm>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Main className="button-example">
        <Cards
          title="Journals List"
          isbutton={
            <div className="card-radio">
              <Button htmlType="submit" type="primary" onClick={showModal}>
                Add journals
              </Button>
            </div>
          }
        >
          <Table
            dataSource={journalsList.data}
            columns={columns}
            pagination={{
              showSizeChanger: true,
              pageSize: defaultPageSize,
              total: journalsList?.options?.totalDocs,
              onChange: onPageChange,
              pageSizeOptions: ["10", "50", "100", "500", "1000"],
            }}
          />
        </Cards>

        {/* ----create seasons MODAL--------- */}
        <Modal
          title={setUpdateIdRef.current !== ""?"Edit Journal":"Add Journal"}
          open={isModalOpen}
          onOk={form.submit}
          onCancel={handleCancel}
          okText="Submit"
        >
          <Form layout="vertical" onFinish={onSubmit} form={form}>
            <Row gutter={20}>
              <Col md={24} xs={24}>
                <Form.Item
                  id="question"
                  name="question"
                  label="question"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default Journals;
