import { combineReducers } from "redux";
import authReducer from "./authentication/reducers";
import ChangeLayoutMode from "./themeLayout/reducers";
import chartContentReducer from "./chartContent/reducers";
import Calender from "./calendar/reducers";
import staticReducer from "./staticpage/reducers";
import UserReducer from "./users/reducers";
import dashboardReducer from "./dashboard/reducers";

const rootReducers = combineReducers({
  auth: authReducer,
  ChangeLayoutMode,
  chartContent: chartContentReducer,
  static: staticReducer,
  user: UserReducer,
  dashboard: dashboardReducer,
  Calender,
});

export default rootReducers;
