import React from "react";
import { Menu } from "antd";
import { NavLink, useRouteMatch } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import propTypes from "prop-types";
// import { getItem } from "../utility/localStorageControl";

// const userDetails = getItem("userDetails");

// console.log("userDetails ==> ", userDetails);

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
  const { path } = useRouteMatch();

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split("/");

  // const { onRtlChange, onLtrChange, modeChangeDark, modeChangeLight, modeChangeTopNav, modeChangeSideNav } = events;
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
  );
  const onOpenChange = (keys) => {
    setOpenKeys([keys.length && keys[keys.length - 1]]);
  };
  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      theme={darkMode && "dark"}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? "dashboard"
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
          : []
      }
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      <Menu.Item
        icon={!topMenu && <FeatherIcon icon="activity" />}
        key="dashboard"
      >
        <NavLink
          onClick={toggleCollapsed}
          to={{
            pathname: `${path}`,
            pageName: "dashboard",
          }}
        >
          Dashboard
        </NavLink>
      </Menu.Item>
      <Menu.Item
        className="active"
        key="users"
        icon={!topMenu && <FeatherIcon icon="file-minus" />}
      >
        <NavLink
          onClick={toggleCollapsed}
          to={{
            pathname: `${path}/users`,
            pageName: "users",
          }}
        >
          Users
        </NavLink>
      </Menu.Item>

      <Menu.Item
        className="active"
        key="isdn"
        icon={!topMenu && <FeatherIcon icon="box" />}
      >
        <NavLink
          onClick={toggleCollapsed}
          to={{
            pathname: `${path}/isbn`,
            pageName: "ISBN",
          }}
        >
          ISBN
        </NavLink>
      </Menu.Item>

      <Menu.Item
        className="active"
        key="seasons"
        icon={!topMenu && <FeatherIcon icon="file-minus" />}
      >
        <NavLink
          onClick={toggleCollapsed}
          to={{
            pathname: `${path}/seasons`,
            pageName: "seasons",
          }}
        >
          Seasons
        </NavLink>
      </Menu.Item>
      <Menu.Item
        className="active"
        key="poems"
        icon={!topMenu && <FeatherIcon icon="file-text" />}
      >
        <NavLink
          onClick={toggleCollapsed}
          to={{
            pathname: `${path}/poems`,
            pageName: "poems",
          }}
        >
          Poems
        </NavLink>
      </Menu.Item>
      <Menu.Item
        className="active"
        key="artwork"
        icon={!topMenu && <FeatherIcon icon="pie-chart" />}
      >
        <NavLink
          onClick={toggleCollapsed}
          to={{
            pathname: `${path}/artwork`,
            pageName: "artwork",
          }}
        >
          ArtWork
        </NavLink>
      </Menu.Item>
      <Menu.Item
        className="active"
        key="giftstore"
        icon={!topMenu && <FeatherIcon icon="pie-chart" />}
      >
        <NavLink
          onClick={toggleCollapsed}
          to={{
            pathname: `${path}/store`,
            pageName: "store",
          }}
        >
          Store
        </NavLink>
      </Menu.Item>
      <Menu.Item
        className="active"
        key="meditations"
        icon={!topMenu && <FeatherIcon icon="stop-circle" />}
      >
        <NavLink
          onClick={toggleCollapsed}
          to={{
            pathname: `${path}/meditations`,
            pageName: "meditations",
          }}
        >
          Meditations
        </NavLink>
      </Menu.Item>

      <Menu.SubMenu
        title="Static Pages"
        className="active"
        key="policy"
        icon={!topMenu && <FeatherIcon icon="align-left" />}
      >
        <Menu.Item className="active" key="policy">
          <NavLink
            onClick={toggleCollapsed}
            to={{
              pathname: `${path}/policy`,
              pageName: "policy",
            }}
          >
            Privacy Policy
          </NavLink>
        </Menu.Item>

        <Menu.Item className="active" key="terms">
          <NavLink
            onClick={toggleCollapsed}
            to={{
              pathname: `${path}/terms`,
              pageName: "terms",
            }}
          >
            Terms And Conditions
          </NavLink>
        </Menu.Item>

        <Menu.Item className="active" key="devotional">
          <NavLink
            onClick={toggleCollapsed}
            to={{
              pathname: `${path}/devotional`,
              pageName: "devotional",
            }}
          >
            How to use Devotional
          </NavLink>
        </Menu.Item>

        <Menu.Item className="active" key="about">
          <NavLink
            onClick={toggleCollapsed}
            to={{
              pathname: `${path}/about`,
              pageName: "about",
            }}
          >
            About
          </NavLink>
        </Menu.Item>

        <Menu.Item className="active" key="share">
          <NavLink
            onClick={toggleCollapsed}
            to={{
              pathname: `${path}/share`,
              pageName: "share",
            }}
          >
            How to share Rustling app
          </NavLink>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
