import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");


export const getMeditations = (page, limit) => {
  return async (dispatch) => {
    const resp = await DataService.get(
      API.meditations.meditationsGet + "?page=" + page + "&limit=" + limit
    );
    if (!resp.data.error) {
      return resp.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const meditationsAdd = (value) => {
  console.log("valuevaluevaluevalue", value);
  return async (dispatch) => {
    const resp = await DataService.post(API.meditations.meditationsGet, value);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
export const meditationsUpdate = (id, value) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.meditations.meditationsGet + "/" + id,
      value
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const deleteLists = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(
      API.meditations.meditationsGet + "/" + id
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
