import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, Modal, Table, Popconfirm, Image } from "antd";
import { Main } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Button } from "../../components/buttons/buttons";
import {
  seasonsAdd,
  getSeasons,
  deleteLists,
  seasonsUpdate,
} from "../../redux/seasons/actionCreator";
import useStateRef from "react-usestateref";
import { DeleteFilled } from "@ant-design/icons";
import { getUrlData } from "redux/staticpage/actionCreator";
import { useDispatch,useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";

const Seasons = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { seasons } = useSelector((state) => state.static);
  const [file, setFile] = useState("");
  const [ImageUpload, setImageUpload] = useState("");
  const [seasonsList, setSeasonsList] = useState([]);
  const [updateId, setUpdateId, setUpdateIdRef] = useStateRef("");

  const dispatch = useDispatch();

  const [defaultPageSize, setDefaultPageSize] = useState(10);

  useEffect(() => {
    onPageChange(1, 10);
    const getCall = async () => {    
      const res = await dispatch(getUrlData());      
    };
    getCall();
  }, []);

  const changeHandler = (e) => {
    setImageUpload(e.target.files[0]);
    setFile(
      <div className="Priv_img">
        <img src={URL.createObjectURL(e.target.files[0])} />
        <div className="remove_img" onClick={removeImage}>
          <DeleteFilled style={{ fontSize: "15px", color: "#fff" }} />
        </div>
      </div>
    );
  };
  const removeImage = () => {
    setFile("");
  };


  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setUpdateId("");
    setImageUpload("");
    setFile("");
  };

  //CREATE API
  const onSubmit = async (value) => {

    delete value.image;
    var form_data = new FormData();
    form_data.append("image", ImageUpload);
    for (var key in value) {

      form_data.append(key, value[key]);
    }
    for (const pair of form_data.entries()) {
      // console.log(pair, "pair");
    }

    if (setUpdateIdRef.current !== "") {
      let Update = await dispatch(seasonsUpdate(setUpdateIdRef.current, form_data));
      if (Update) {
        onPageChange(1, 10);
        setIsModalOpen(false);
        setUpdateId("");
      }
    } else {
      let create = await dispatch(seasonsAdd(form_data));

      if (create) {
        onPageChange(1, 10);
        setIsModalOpen(false);
        setImageUpload("");
        setFile("");
      }
    }
    form.resetFields();
  };

  //DELETE API
  const seasonsDelete = async (id) => {
    const deleted = await dispatch(deleteLists(id));
    if (deleted) {
      onPageChange(1, 10);
    }
  };

  //PAGE SIZE SET FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setDefaultPageSize(pageSizes);
    console.log("current", page, pageSizes);
    let list = await dispatch(getSeasons(page, pageSizes));
    if (list) {
      setSeasonsList(list);
    }
  };
  //EDIT API
  const editSeasons = (value) => {
    setImageUpload(seasons+ value.image);
    setFile(
      <div className="Priv_img">
        <img src={seasons+ value.image} />
        <div className="remove_img" onClick={removeImage}>
          <DeleteFilled style={{ fontSize: "15px", color: "#fff" }} />
        </div>
      </div>
    );
    form.setFieldsValue({ image: value.image });
    setUpdateId(value._id);
    setIsModalOpen(true);
    // console.log("🚀 ~ file: seasons.js ~ line 66 ~ editSeasons ~ abc", value);
    form.setFieldsValue(value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (value) => {
        return <span>{value && value ? value : "-"}</span>;
      },
    },
    {
      title: "Season Image",
      render: (value) => {
        return (
          <span>
            <>
              {seasons&& seasons? (
                <Image width={100} src={seasons+ value.image} />
              ) : (
                "-"
              )}
            </>
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (value, record) => {
        return (
          <>
            <a
              className="btn-icon"
              onClick={() => editSeasons(record)}
              to="#"
              shape="circle"
            >
              <FeatherIcon icon="edit" size={16} />
            </a>

            <Popconfirm
              title="Are you sure to delete this season?"
              onConfirm={() => seasonsDelete(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">
                {" "}
                <FeatherIcon size={14} icon="trash-2" />
              </a>
            </Popconfirm>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Main className="button-example">
        <Cards
          title="Seasons List"
          isbutton={
            <div className="card-radio">
              <Button htmlType="submit" type="primary" onClick={showModal}>
                Add Seasons
              </Button>
            </div>
          }
        >
          <Table
            dataSource={seasonsList.data}
            columns={columns}
            pagination={{
              showSizeChanger: true,
              pageSize: defaultPageSize,
              total: seasonsList?.options?.totalDocs,
              onChange: onPageChange,
              pageSizeOptions: ["10", "50", "100", "500", "1000"],
            }}
          />
        </Cards>

        {/* ----create seasons MODAL--------- */}
        <Modal
          title={
            setUpdateIdRef.current !== "" ? "Edit Season" : "Add Season"
          }
          open={isModalOpen}
          onOk={form.submit}
          onCancel={handleCancel}
          okText="Submit"
          maskClosable={false}
        >
          <Form layout="vertical" onFinish={onSubmit} form={form}>
            <Row gutter={20}>
              <Col md={24} xs={24}>
                <Form.Item
                  id="name"
                  name="name"
                  label="Name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item
                  label="Season Image"
                  name="image"
                  rules={[{ required: true }]}
                >
                  {file ? (
                    <div>{file}</div>
                  ) : (
                    <div className="Upload_product">
                      <Input
                        type="file"
                        placeholder="Profile Pic"
                        title=""
                        accept="image/*"
                        onChange={changeHandler}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item
                  id="status"
                  name="status"
                  label="Status"
                  initialValue={"active"}
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select a status">
                    <Select.Option value="active">Active</Select.Option>
                    <Select.Option value="inactive">Inactive</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default Seasons;
