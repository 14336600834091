import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");


export const poemsAdd = (value) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.poems.poemsLists, value);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const poemsUpdate = (id, value) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.poems.poemsLists + "/" + id, value);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const getPoems = (page, limit) => {
  return async (dispatch) => {
    const resp = await DataService.get(
      API.poems.poemsLists + "?page=" + page + "&limit=" + limit
    );
    if (!resp.data.error) {
      return resp.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const getPoemsFilter = (page, limit,id) => {
  return async (dispatch) => {
    const resp = await DataService.get(
      API.poems.poemsLists + "?page=" + page + "&limit=" + limit+"&seasonsId="+id
    );
    if (!resp.data.error) {
      return resp.data;
    } else {
      message.error(resp.data.message);
    }
  };
};


export const deleteLists = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.poems.poemsLists + "/" + id);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
export const getSeasons = (value) => {
  return async (dispatch) => {
    const resp = await DataService.get(API.seasons.seasonsCreate, value);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
