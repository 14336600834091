import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");


export const seasonsAdd = (value) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.seasons.seasonsCreate, value);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
export const seasonsUpdate = (id, value) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.seasons.seasonsCreate + "/" + id,
      value
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const getSeasons = (page, limit) => {
  return async (dispatch) => {
    const resp = await DataService.get(
      API.seasons.seasonsCreate + "?page=" + page + "&limit=" + limit
    );
    if (!resp.data.error) {
      return resp.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const getAllSeasons = () => {
  return async (dispatch) => {
    const resp = await DataService.get(
      API.seasons.seasonsCreate + "/getAllSeasons"
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const deleteLists = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.seasons.seasonsCreate + "/" + id);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
