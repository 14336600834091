import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Modal,
  Table,
  Popconfirm,
  Image,
  InputNumber,
} from "antd";
import { Main } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Button } from "../../components/buttons/buttons";
import ReactAudioPlayer from "react-audio-player";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  poemsAdd,
  getPoems,
  deleteLists,
  poemsUpdate,
  getSeasons,
  getPoemsFilter,
} from "../../redux/poems/actionCreator";
import { getAllSeasons } from "redux/seasons/actionCreator";
import { getUrlData } from "redux/staticpage/actionCreator";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import useStateRef from "react-usestateref";
import { shortWord } from "../../utility/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { DeleteFilled } from "@ant-design/icons";
import "./poems.css";
import { useRouteMatch } from "react-router-dom";

const Poems = () => {
  const { path } = useRouteMatch();
  const [form] = Form.useForm();
  const { artWorksUrl, audioUrl, soundsUrl } = useSelector(
    (state) => state.static
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [poemsList, setpoemsList] = useState([]);
  const [updateId, setUpdateId, setUpdateIdRef] = useStateRef("");

  const dispatch = useDispatch();

  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [file, setFile] = useState("");
  const [fileSound, setFileSound] = useState("");
  const [GroupList, setGroupList] = useState([]);
  const [soundUpload, setSoundUpload] = useState("");
  const [ImageUpload, setImageUpload] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    onPageChangeFilter(1, 10, filter);
    GetQuestionGroup();
    const getCall = async () => {
      const res = await dispatch(getUrlData());
    };
    getCall();
  }, []);

  const AudioChangeHandler = (e) => {
    setSoundUpload(e.target.files[0]);
    setFileSound(
      <div className="Priv_imgs">
        <ReactAudioPlayer
          src={URL.createObjectURL(e.target.files[0])}
          controls
        />
        <div className="remove_img" onClick={removeImageSound}>
          <DeleteFilled style={{ fontSize: "15px", color: "#fff" }} />
        </div>
      </div>
    );
  };
  const removeImageSound = () => {
    setFileSound("");
  };

  // image upload ---------
  const changeHandler = (e) => {
    setImageUpload(e.target.files[0]);
    setFile(
      <div className="Priv_img">
        <img src={URL.createObjectURL(e.target.files[0])} />
        <div className="remove_img" onClick={removeImage}>
          <DeleteFilled style={{ fontSize: "15px", color: "#fff" }} />
        </div>
      </div>
    );
  };
  const removeImage = () => {
    setFile("");
  };

  const showModal = () => {
    setSoundUpload("");
    setFileSound("");
    setImageUpload("");
    setFile("");

    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setUpdateId("");
    setIsModalOpen(false);
    setSoundUpload("");
    setFileSound("");
    setImageUpload("");
    setFile("");
    form.setFieldsValue({ title: "" });
    form.setFieldsValue({ bibleBookName: "" });
    form.setFieldsValue({ bibleChapterNumber: "" });
    form.setFieldsValue({ bibleVerseDescription: "" });
    form.setFieldsValue({ bibleVerseIndexNumber: "" });    
    form.setFieldsValue({bibleVerseNumber:""});
    form.setFieldsValue({ content: "" });
    form.setFieldsValue({ poemIndex: "" });
    form.setFieldsValue({ shortDescription: "" });
  };

  //CREATE API
  const onSubmit = async (value) => {
    // delete value.content;

    console.log("row valu1=", value);
    if (!value.bibleVerseNumber) {
      value.bibleVerseNumber="";
    }
    delete value.sound;
    delete value.artWorkImages;

    console.log("row valu2=", value);

    var form_data = new FormData();
    form_data.append("sound", soundUpload);
    form_data.append("artWorkImages", ImageUpload);
    for (var key in value) {
      form_data.append(key, value[key]);
    }
    console.log(form_data, "=form data");
    for (const pair of form_data.entries()) {
      // console.log("🚀 ~ file: poems.js ~ line 135 ~ onSubmit ~ pair", pair);
    }

    if (setUpdateIdRef.current !== "") {
      let Update = await dispatch(
        poemsUpdate(setUpdateIdRef.current, form_data)
      );
      if (Update) {
        onPageChangeFilter(currentPage, 10, filter);
        setIsModalOpen(false);
        setUpdateId("");
        handleCancel();
      }
    } else {
      let create = await dispatch(poemsAdd(form_data));

      if (create) {
        onPageChangeFilter(currentPage, 10, filter);
        setIsModalOpen(false);
        // form.resetFields();
        handleCancel();
        setSoundUpload("");
        setFileSound("");
        setImageUpload("");
        setFile("");
      }
    }
    // form.resetFields();
  };

  //GET  seasons API
  const GetQuestionGroup = async () => {
    const List = await dispatch(getAllSeasons());

    if (List) {
      setGroupList(List);
    }
  };

  //DELETE API
  const poemsDelete = async (id) => {
    const deleted = await dispatch(deleteLists(id));
    if (deleted) {
      onPageChangeFilter(currentPage, 10, filter);
      setFilter("");
    }
  };

  const onPageChangeFilter = async (page, pageSizes, id) => {
    setDefaultPageSize(pageSizes);
    console.log("current", page, pageSizes);
    setCurrentPage(page);
    let list = await dispatch(getPoemsFilter(page, pageSizes, id));
    if (list) {
      setpoemsList(list);
    }
  };
  //EDIT API
  const editPoems = (value) => {
    setSoundUpload(soundsUrl + value.sound);
    setFileSound(
      <div className="Priv_img">
        <ReactAudioPlayer src={soundsUrl + value.sound} controls />
        <div className="remove_img" onClick={removeImageSound}>
          <DeleteFilled style={{ fontSize: "15px", color: "#fff" }} />
        </div>
      </div>
    );
    form.setFieldsValue({
      ...value,
      sound: value.sound,
      artWorkImages: value.artWorkImages,
      seasonsId: value.seasonsId._id,
    });

    setImageUpload(artWorksUrl + value.artWorkImages);
    setFile(
      <div className="Priv_img">
        <img src={artWorksUrl + value.artWorkImages} />
        <div className="remove_img" onClick={removeImage}>
          <DeleteFilled style={{ fontSize: "15px", color: "#fff" }} />
        </div>
      </div>
    );
    // form.setFieldsValue({ artWorkImages: value.artWorkImages });

    setUpdateId(value._id);
    setIsModalOpen(true);
    // form.setFieldsValue(...value);
  };

  const seasonChange = async (e) => {
    setFilter(e);
    onPageChangeFilter(0, 10, e);
    // let list = await dispatch(getPoems(page, pageSizes));
    //     if (list) {
    //       setpoemsList(list);
    //     }
  };

  const columns = [
    {
      title: "Poem Name",
      dataIndex: "title",
      isSortable: true,
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (value) => {
        return <span>{value && value ? value : "-"}</span>;
      },
    },
    {
      title: "Index",
      dataIndex: "poemIndex",
      isSortable: true,
      // sorter: (a, b) => a?.poemIndex?.toString()?.localeCompare(b?.poemIndex?.toString()),
      sorter: (a, b) => a?.poemIndex - b?.poemIndex,
      render: (value) => {
        return <span>{value && value ? value : "-"}</span>;
      },
    },
    // {
    //   title: "Content",

    //   render: (value) => {
    //     return <>{value.content ? shortWord(value.content) : "-"}</>;
    //   },
    // },
    {
      title: "Seasons Name",
      dataIndex: "seasonsId",
      // filters: [
      //   {
      //     text: "Winter",
      //     value: "Winter",
      //   },
      //   {
      //     text: "Summer",
      //     value: "Summer",
      //   },
      //   {
      //     text: "Spring",
      //     value: "Spring",
      //   },
      //   {
      //     text: "Fall",
      //     value: "Fall",
      //   },
      // ],
      // filters:
      //   GroupList?.map((data) => {
      //     return {text:data.name, value:data._id}

      //   }),
      // onFilter: (value, record) => {
      //   // console.log("record=",record?.seasonsId?.name, "value=", value )
      //   return record?.seasonsId?.name === value;
      // },
      render: (value) => {
        return (
          <span>
            {/* {console.log("sasasas", value && value.name ? value.name : "-")} */}
            {value && value.name ? value.name : "-"}
          </span>
        );
      },
    },
    {
      title: "Art Work Images",
      dataIndex: "artWorkImages",
      render: (value) => {
        return (
          <span>
            {/* {console.log("images", value)} */}
            {value && value.length > 0 ? (
              <>
                {value[0] && artWorksUrl ? (
                  <Image width={50} src={artWorksUrl + value[0]} />
                ) : (
                  "-"
                )}
              </>
            ) : (
              "-"
            )}
          </span>
        );
      },
    },
    {
      title: "Sound",
      dataIndex: "sound",
      width: "12%",
      render: (value) => {
        return (
          <span>
            {/* {console.log("images", value)} */}
            {value && value.length > 0 ? (
              <>
                {soundsUrl && soundsUrl ? (
                  <ReactAudioPlayer src={soundsUrl + value} controls />
                ) : (
                  "-"
                )}
              </>
            ) : (
              "-"
            )}
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      width: "15%",
      render: (value, record) => {
        return (
          <>
            <a
              className="btn-icon"
              onClick={() => editPoems(record)}
              to="#"
              shape="circle"
            >
              <FeatherIcon icon="edit" size={16} />
            </a>{" "}
            <Popconfirm
              title="Are you sure to delete this poem?"
              onConfirm={() => poemsDelete(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <a href="#" className="btn-icon">
                {" "}
                <FeatherIcon size={14} icon="trash-2" />
              </a>
            </Popconfirm>
          </>
        );
      },
    },
    {
      title: "Journals",
      dataIndex: "Journals",
      width: "10%",
      render: (value, record) => {
        return (
          <>
            <NavLink
              className="btn-icon"
              exact
              to={{
                pathname: `${path}/${record._id}`,
                state: { seasonsId: record.seasonsId._id, poemId: record._id },
              }}
            >
              {/* <NavLink exact to={`${path}/${record._id}`,state:{seasonsId:record.seasonsId._id}}> */}
              <FeatherIcon icon="book" size={16} />
            </NavLink>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Main className="button-example">
        <Cards
          title="Poems List"
          isbutton={
            <div className="card-radio">
              <Select
                defaultValue={"Seasons"}
                onChange={seasonChange}
                style={{ width: "135px", padding: "5px" }}
              >
                {GroupList &&
                  GroupList?.map((data) => {
                    return (
                      <Select.Option value={data._id}>
                        {data.name}
                      </Select.Option>
                    );
                  })}
                <Select.Option value={""}>{"none"}</Select.Option>
              </Select>
              <Button htmlType="submit" type="primary" onClick={showModal}>
                Add Poems
              </Button>
            </div>
          }
        >
          {/* 1234567890123456789012 */}
          {!filter ? (
            <Table
              dataSource={poemsList?.data}
              columns={columns}
              pagination={{
                showSizeChanger: true,
                pageSize: 10,
                total: poemsList?.options?.totalDocs,
                onChange: (page, pagesize) => {
                  onPageChangeFilter(page, pagesize, filter);
                },
                pageSizeOptions: ["10", "50", "100", "500", "1000"],
                // current
              }}
            />
          ) : (
            <Table
              dataSource={poemsList?.data}
              columns={columns}
              pagination={{
                showSizeChanger: true,
                pageSize: 10,
                total: poemsList?.options?.totalDocs,
                onChange: (page, pagesize) => {
                  onPageChangeFilter(page, pagesize, filter);
                },
                pageSizeOptions: ["10", "50", "100", "500", "1000"],
                // current
              }}
            />
          )}
        </Cards>

        {/* ----create seasons MODAL--------- */}
        <Modal
          title={setUpdateIdRef.current !== "" ? "Edit Poem" : "Add Poem"}
          open={isModalOpen}
          onOk={form.submit}
          onCancel={handleCancel}
          okText="Submit"
          maskClosable={false}
        >
          <Form layout="vertical" onFinish={onSubmit} form={form}>
            <Row gutter={20}>
              <Col md={24} xs={24}>
                <Form.Item
                  name="title"
                  label="Poem Name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item
                  name="shortDescription"
                  label="Short Description"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item
                  // label="Content"
                  name="content"
                  valuePropName="data"
                  getValueFromEvent={(event, editor) => {
                    const data = editor?.getData();
                    return data;
                  }}
                  rules={[{ required: true, message: "Please enter the body" }]}
                >
                  <CKEditor editor={ClassicEditor} />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  name="seasonsId"
                  label="Select season"
                  rules={[{ required: true }]}
                >
                  <Select style={{ width: "100%" }}>
                    {GroupList &&
                      GroupList?.map((data) => {
                        return (
                          <Select.Option value={data._id}>
                            {data.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  name="bibleBookName"
                  label="Bible Book Name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  name="bibleChapterNumber"
                  label="Bible Chapter Number"
                  rules={[{ required: true }]}
                >
                  <InputNumber type="number" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item name="bibleVerseNumber" label="Bible Verse Number">
                  <InputNumber type="number" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  name="bibleVerseIndexNumber"
                  label="Bible Verse Index Number"
                  rules={[{ required: true }]}
                >
                  <InputNumber type="number" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  name="poemIndex"
                  label="Poem Index"
                  rules={[{ required: true }]}
                >
                  <InputNumber type="number" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item
                  name="bibleVerseDescription"
                  label="Bible Description"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item
                  name="artWorkImages"
                  label="ArtWorkImages"
                  rules={[{ required: true }]}
                >
                  {file ? (
                    <div>{file}</div>
                  ) : (
                    <div className="Upload_product">
                      <Input
                        type="file"
                        placeholder="Profile Pic"
                        title=""
                        accept="image/*"
                        onChange={changeHandler}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item
                  name="sound"
                  label="Sound"
                  rules={[{ required: true }]}
                >
                  {fileSound ? (
                    <div>{fileSound}</div>
                  ) : (
                    <div className="Upload_product">
                      <Input
                        type="file"
                        placeholder="Profile Pic"
                        title=""
                        accept="audio/*"
                        onChange={AudioChangeHandler}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default Poems;
