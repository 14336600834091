import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Modal,
  Table,
  Popconfirm,
  Image,
} from "antd";
import { Main } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Button } from "../../components/buttons/buttons";
import {
  artWorkAdd,
  getArtwork,
  deleteLists,
  artWorkUpdate,
} from "../../redux/artwork/actionCreator";
import { getUrlData } from "redux/staticpage/actionCreator";
import useStateRef from "react-usestateref";
import { DeleteFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import FormItem from "antd/es/form/FormItem";

const ArtWork = () => {
  const [form] = Form.useForm();
  const { artWorksUrl } = useSelector((state) => state.static);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [artWorkList, setArtWorkList] = useState([]);
  const [updateId, setUpdateId, setUpdateIdRef] = useStateRef("");
  const [file, setFile] = useState("");
  const [ImageUpload, setImageUpload] = useState("");

  const dispatch = useDispatch();

  const [defaultPageSize, setDefaultPageSize] = useState(10);

  useEffect(() => {
    onPageChange(1, 10);
    const getCall = async () => {
      const res = await dispatch(getUrlData());
    };
    getCall();
  }, []);

  // image upload ---------
  const changeHandler = (e) => {
    setImageUpload(e.target.files[0]);
    setFile(
      <div className="Priv_img">
        <img src={URL.createObjectURL(e.target.files[0])} />
        <div className="remove_img" onClick={removeImage}>
          <DeleteFilled style={{ fontSize: "15px", color: "#fff" }} />
        </div>
      </div>
    );
  };
  const removeImage = () => {
    setFile("");
  };

  const showModal = () => {
    setIsModalOpen(true);
    setImageUpload("");
    setFile("");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setImageUpload("");
    setFile("");
    form.resetFields();
    setUpdateId("");
  };

  console.log("artwork list=", artWorkList);

  //CREATE API
  const onSubmit = async (value) => {
    // delete value.artWorkImages;
    var form_data = new FormData();

    form_data.append("image", ImageUpload);
    for (var key in value) {
      form_data.append(key, value[key]);
    }
    for (const pair of form_data.entries()) {
      // console.log(pair, "pair");
    }
    if (setUpdateIdRef.current !== "") {
      let Update = await dispatch(
        artWorkUpdate(setUpdateIdRef.current, form_data)
      );

      if (Update) {
        onPageChange(1, 10);
        setIsModalOpen(false);
        setUpdateId("");
      }
    } else {
      let create = await dispatch(artWorkAdd(form_data));

      if (create) {
        onPageChange(1, 10);
        setIsModalOpen(false);
        setImageUpload("");
        setFile("");
      }
    }
    form.resetFields();
  };

  //DELETE API
  const artWorkDelete = async (id) => {
    const deleted = await dispatch(deleteLists(id));
    if (deleted) {
      onPageChange(1, 10);
    }
  };
  //PAGE SIZE SET FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setDefaultPageSize(pageSizes);
    console.log("current", page, pageSizes);
    let list = await dispatch(getArtwork(page, pageSizes));
    console.log("new list=", list);
    if (list) {
      setArtWorkList(list);
    }
  };
  //EDIT API
  const editArtwork = (value) => {
    console.log("editValue:", value);
    setImageUpload(artWorksUrl + value.image);
    setFile(
      <div className="Priv_img">
        <img src={artWorksUrl + value.image} />
        <div className="remove_img" onClick={removeImage}>
          <DeleteFilled style={{ fontSize: "15px", color: "#fff" }} />
        </div>
      </div>
    );
    form.setFieldsValue({ artWorkImages: value.image });
    setUpdateId(value._id);
    setIsModalOpen(true);
    form.setFieldsValue(value);
    console.log("valuepppp", artWorksUrl + value.image);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (value) => {
        return <span>{value && value ? value : "-"}</span>;
      },
    },
    {
      title: "Art Work Images",
      render: (value) => {
        return (
          <span>
            <>
              {artWorksUrl && artWorksUrl ? (
                <Image width={100} src={artWorksUrl + value.image} />
              ) : (
                "-"
              )}
            </>
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (value, record) => {
        return (
          <>
            <a
              className="btn-icon"
              onClick={() => editArtwork(record)}
              to="#"
              shape="circle"
            >
              <FeatherIcon icon="edit" size={16} />
            </a>

            <Popconfirm
              title="Are you sure to delete this artwork?"
              onConfirm={() => artWorkDelete(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">
                {" "}
                <FeatherIcon size={14} icon="trash-2" />
              </a>
            </Popconfirm>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Main className="button-example">
        <Cards
          title="Art Work List"
          isbutton={
            <div className="card-radio">
              <Button htmlType="submit" type="primary" onClick={showModal}>
                Add Artwork
              </Button>
            </div>
          }
        >
          <Table
            dataSource={artWorkList.data}
            columns={columns}
            pagination={{
              showSizeChanger: true,
              pageSize: defaultPageSize,
              total: artWorkList?.options?.totalDocs,
              onChange: onPageChange,
              pageSizeOptions: ["10", "50", "100", "500", "1000"],
            }}
          />
        </Cards>

        {/* ----create seasons MODAL--------- */}
        <Modal
          title={setUpdateIdRef.current !== "" ? "Edit Artwork" : "Add Artwork"}
          open={isModalOpen}
          onOk={form.submit}
          onCancel={handleCancel}
          okText="Submit"
        >
          <Form layout="vertical" onFinish={onSubmit} form={form}>
            <Row gutter={20}>
              <Col md={24} xs={24}>
                <Form.Item
                  id="name"
                  name="name"
                  label="Name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item
                  name="artWorkImages"
                  label="ArtWorkImages"
                  rules={[{ required: true }]}
                >
                  {file ? (
                    <div>{file}</div>
                  ) : (
                    <div className="Upload_product">
                      <Input
                        type="file"
                        placeholder="Profile Pic"
                        title=""
                        accept="image/*"
                        onChange={changeHandler}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default ArtWork;
