import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");


export const journalsAdd = (value) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.journals.getJournals, value);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
export const journalsUpdate = (id, value) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.journals.getJournals + "/" + id,
      value
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const getJournals = (page, limit, poemId) => {
  return async (dispatch) => {
    const resp = await DataService.get(
      API.journals.getJournals +
        "?page=" +
        page +
        "&limit=" +
        limit +
        "&poemId=" +
        poemId
    );
    if (!resp.data.error) {
      return resp.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const deleteLists = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.journals.getJournals + "/" + id);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
