const actions = {
    DASHBOARD_DATA: 'DASHBOARD_DATA',


    dashboardCountData: data => {
        return {
            type: actions.DASHBOARD_DATA,
            data,
        };
    },
}

export default actions;