import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

export const getStore = (page, limit) => {
  return async (dispatch) => {
    const resp = await DataService.get(
      API.store.getStore + "?page=" + page + "&limit=" + limit
    );
    if (!resp.data.error) {
      return resp.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const storeAdd = (value) => {
  return async (dispatch) => {
    const resp = await DataService.post(API.store.getStore, value);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const storeUpdate = (id, value) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.store.getStore + "/" + id, value);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const deleteStore = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.store.getStore + "/" + id);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
