import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Modal, Table, Popconfirm,Switch } from "antd";
import {
  PlusOutlined,
  ExclamationCircleOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons";
import { Main } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Button } from "../../components/buttons/buttons";
// import { getUserData } from "redux/users/actionCreator";
import { getUserData, postUserData, blockUserData } from "redux/users/actionCreator";
import useStateRef from "react-usestateref";
import { useDispatch, useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { useLocation } from "react-router-dom";

const Users = () => {
  let location = useLocation();
  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userActive, setUserActive] = useState(true);
  const [userDeActive, setUserDeActive] = useState(false);
  const [userList, setUserList] = useState([]);
  const [pageNo, setPageNo] = useState(1)
  console.log("userList", userList?.data?.data);

  const [updateId, setUpdateId, setUpdateIdRef] = useStateRef("");

  const dispatch = useDispatch();

  const [defaultPageSize, setDefaultPageSize] = useState(10);

  useEffect(() => {
    onPageChange(1, 10);
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setUpdateId("");
  };
  console.log("pagis", userList);


  //CREATE API
  const onSubmit = async (value) => {
    value.type = "text";
    value.seasonsId = location && location.state && location.state.seasonsId;
    value.poemId = location && location.state && location.state.poemId;

    if (setUpdateIdRef.current !== "") {
      let Update = await dispatch(
        journalsUpdate(setUpdateIdRef.current, value)
      );
      if (Update) {
        onPageChange(1, 10);
        setIsModalOpen(false);
        setUpdateId("");
      }
    } else {
      let create = await dispatch(journalsAdd(value));
      console.log("create", value);
      if (create) {
        onPageChange(1, 10);
        setIsModalOpen(false);
      }
    }
    form.resetFields();
  };
 

  //PAGE SIZE SET FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setDefaultPageSize(pageSizes);
    console.log("page=", page)
    setPageNo(page);
    let list = await dispatch(
      getUserData(page, pageSizes)
    );
    if (list) {
      setUserList(list);
    }
  };
  //EDIT API
 

  const changeStatusYes = async (id) => {
    Modal.confirm({
      title: "Are you sure you want to block the user?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => userDeactivateYes(id, "inactive"),
      okCancel: userDeactivateNo,
      okType: "primary",
    });
  };

  const userDeactivateYes = async (id, checked) => {
    const payload = {userId:id, status:checked}
    const response = dispatch(blockUserData(payload));
    if(response){
      setTimeout(() => {
            onPageChange(pageNo,10);
          }, 2000);
    }    
  };

  const userDeactivateNo = () => {
    setUserActive(true);
  };

  const changeStatusNo = async (id, checked) => {
    Modal.confirm({
      title: "Do you want to unblock the user?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => userActivateYes(id, "active"),
      okCancel: userActivateNo,
      okType: "primary",
    });
  };

  const userActivateYes = async (id, checked) => {
    const payload = {userId:id, status:checked}
    const response = dispatch(blockUserData(payload));
    if(response){
      setTimeout(() => {
            onPageChange(pageNo,10);
          }, 2000);
    }    
  };

  const userActivateNo = () => {
    setUserDeActive(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      render: (value,record) => {
        return <span>{value && value ? record?.firstName + " " +record?.lastName : "-"}</span>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (value) => {
        return <span>{value && value ? value : "-"}</span>;
      },
    },

    {
      title: "User Type",
      dataIndex: "subscriptionData",
      render: (value) => {
        console.log("user value subscription=", value)
        return <span>{value && value?._id ? "Paid" : "Unpaid"}</span>;
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (value, record) => {
        console.log(value, "=user data value",record)
        return (
          <>
            {value==="active" ? (
          <Switch
            checked={userActive}
            className="switchStyle"
            onChange={() => changeStatusYes(record._id)}
            // disabled={o.status === "Live"}
          />
        ) : (
          <Switch
            checked={userDeActive}
            onChange={() => changeStatusNo(record._id)}
            // disabled={o.status === "Live"}
          />
        )}
          </>
        );
      },
    },
  ];
  return (
    <>
      <Main className="button-example">
        <Cards
          title="Users List"         
        >
          <Table
            dataSource={userList.data?.data}
            columns={columns}
            pagination={{
              showSizeChanger: true,
              pageSize: defaultPageSize,
              total: userList?.data?.options?.totalDocs,
              onChange: onPageChange,
              pageSizeOptions: ["10", "50", "100", "500", "1000"],
            }}
          />
        </Cards>

        {/* ----create seasons MODAL--------- */}
        <Modal
          title={setUpdateIdRef.current !== ""?"Edit Journal":"Add Journal"}
          open={isModalOpen}
          onOk={form.submit}
          onCancel={handleCancel}
          okText="Submit"
        >
          <Form layout="vertical" onFinish={onSubmit} form={form}>
            <Row gutter={20}>
              <Col md={24} xs={24}>
                <Form.Item
                  id="firstname"
                  name="firstname"
                  label="First Name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Form.Item
                  id="lastname"
                  name="lastname"
                  label="Last Name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default Users;
