import actions from './actions';

const initialState = {
    dashboardCount: {},
}

const {
    DASHBOARD_DATA
} = actions;


const dashboardReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case DASHBOARD_DATA:
            return {
                ...state,
                dashboardCount: data,
            };
        default:
            return state;
    }
};
export default dashboardReducer;