import Cookies from "js-cookie";
import actions from "./actions";
import { message } from "antd";
import { removeItem, setItem } from "../../utility/localStorageControl";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
} = actions;

const login = (payloads) => {
  console.log("payloads", payloads);
  return async (dispatch) => {
    try {
      const loggedIn = await DataService.post(API.auth.login, payloads);
      if (!loggedIn.data.error) {
        setItem("access_token", loggedIn.data.data.token);
        setItem("userDetails", loggedIn.data.data);
        Cookies.set("logedIn", true);
        dispatch(loginSuccess(true));

        window.location.reload();
        return true;
      } else {
        dispatch(loginErr(loggedIn));
        message.error("invalid Details");
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const changePassword = (payloads) => {
  return async (dispatch) => {
    const resp = await DataService.put(API.auth.changePassword, payloads);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
      return false;
    }
  };
};

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      removeItem("access_token");
      Cookies.remove("logedIn");
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, changePassword };
