import "./static.css";
import React from "react";
import { useEffect, useState } from "react";
import { Card, Row, Col, Button, Form } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import Sidebar from "component/Sidebar";
// import { FIELD_REQUIRED } from "utils/constants";
import { Main } from "../styled";
import { getTermsData, editTermsData } from "redux/staticpage/actionCreator";
import { useDispatch, useSelector } from "react-redux";

const Terms = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm(); 
  const type = "TermsAndCondition";

  useEffect(() => {
    const getCall = async () => {
      const res = await dispatch(getTermsData(type));
      if (res) {
        form.setFieldsValue({ content: res.data.data.content ? res.data.data.content :"" });
      }
    };
    getCall();
  }, []);

  const handleSubmit = async (value) => {
    
    const payload = {
      type: type,
      content: value?.content,
    };

    const resp = await dispatch(editTermsData(payload));
    if (!resp.error) {
      const getCall = async () => {
      
        const res = await dispatch(getTermsData(type));
        if (res) {
        
        }
      };
      getCall();
      // setLoading(false);
    } else {
      const getCall = async () => {
      
        const res = await dispatch(getTermsData(type));
        if (res) {
        
        }
      };
      getCall();
      // setLoading(false);
    }

  };

  return (
    <Main className="button-example">
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Row gutter={25}>
          <Col xl={24} lg={24} xs={24}>
            <Card title="Terms and conditions" size="large">
              <Form.Item
                // label="Terms and conditions"
                name="content"
                valuePropName="data"
                getValueFromEvent={(event, editor) => {
                  const data = editor.getData();
                  return data;
                }}
                rules={[{ required: true, message: "Please enter content" }]}
              >
                <CKEditor editor={ClassicEditor} />
              </Form.Item>
              
              <Col xl={8} lg={8} xs={8} className="term">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handleSubmit}
                    block
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Card>
          </Col>
        </Row>
      </Form>
    </Main>
  );
};

export default Terms;
