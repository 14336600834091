import actions from "./actions";

import { message } from "antd";
import { DataService } from "config/dataService/dataService";
import { API } from "config/api/index";

const {
  getTerms,
  getDevotion,
  getUrl,
  updateDevotion,
  updatePolicy,
  updateTerms,
} = actions;

const getTermsData = (payload) => {
  // console.log(payload);
  return async (dispatch) => {
    try {
      const res = await DataService.get(
        API.statics.getStatic + `?type=${payload}`
      );
      if (!res.data.error) {
        dispatch(getTerms(res));
        // window.location.reload();
        return res;
      } else {
        return res;
      }
      // console.log(" hello user", payload);
    } catch (err) {
      return err;
      //  dispatch(loginErr(err));
    }
  };
};

const getUrlData = () => {
  // console.log(payload);
  return async (dispatch) => {
    try {
      const res = await DataService.get(
        API.staticUrl.getUrl
      );
      if (!res.data.error) {
        dispatch(getUrl(res));
        // window.location.reload();
        return res;
      } else {
        return res;
      }
      // console.log(" hello user", payload);
    } catch (err) {
      return err;
      //  dispatch(loginErr(err));
    }
  };
};

const editTermsData = (payload) => {
  console.log(payload);
  const {content} = payload
 
  return async (dispatch) => {
    try {
      const res = content && await DataService.post(API.statics.getStatic, payload);
      console.log(res, "res");
      if (!res?.data?.error) {
        dispatch(updateTerms(res));
        // window.location.reload();
        return res;
      } else {
      
        return res;
      }
      // console.log(" hello user", payload);
    } catch (err) {
     
      return err;
      //  dispatch(loginErr(err));
    }
  };
};

export { getTermsData, editTermsData,getUrlData };
