const actions = {
  //DEFINE RETURN DATA SET
  GET_TERMS: "GET_TERMS",
  UPDATE_TERMS: "UPDATE_TERMS",
  GET_URL: "GET_URL",
  UPDATE_POLICY: "UPDATE_POLICY",
  GET_DEVOTION: "GET_DEVOTION",
  UPDATE_DEVOTION: "UPDATE_DEVOTION",

  getTerms: (data) => {
    return {
      type: actions.GET_TERMS,
      data,
    };
  },

  updateTerms: (data) => {
    return {
      type: actions.UPDATE_TERMS,
      data,
    };
  },

  getUrl: (data) => {
    return {
      type: actions.GET_URL,
      data,
    };
  },

  updatePolicy: (data) => {
    return {
      type: actions.UPDATE_POLICY,
      data,
    };
  },

  getDevotion: (data) => {
    return {
      type: actions.GET_DEVOTION,
      data,
    };
  },

  updateDevotion: (data) => {
    return {
      type: actions.UPDATE_DEVOTION,
      data,
    };
  },
};

export default actions;
