import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, Modal, Table, Popconfirm } from "antd";
import { Main } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Button } from "../../components/buttons/buttons";
import { isbnUpdateData, isbnAddData, isbnDeleteData, getIsbnData } from "redux/isdn/actionCreator";
import useStateRef from "react-usestateref";

import { useDispatch } from "react-redux";
import FeatherIcon from "feather-icons-react";

const Isbn = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isbnList, setIsbnList] = useState([]);
  const [updateId, setUpdateId, setUpdateIdRef] = useStateRef("");

  const dispatch = useDispatch();

  const [defaultPageSize, setDefaultPageSize] = useState(10);

  useEffect(() => {
    onPageChange(1, 10);
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setUpdateId("");
  };

  //CREATE API
  const onSubmit = async (value) => {
    if (setUpdateIdRef.current !== "") {
      let Update = await dispatch(isbnUpdateData(setUpdateIdRef.current, value));
      if (Update) {
        onPageChange(1, 10);
        setIsModalOpen(false);
        setUpdateId("");
      }
    } else {
      let create = await dispatch(isbnAddData(value));

      if (create) {
        onPageChange(1, 10);
        setIsModalOpen(false);
      }
    }
    form.resetFields();
  };

  //DELETE API
  const isbnDelete = async (id) => {
    const deleted = await dispatch(isbnDeleteData(id));
    if (deleted) {
      onPageChange(1, 10);
    }
  };

  //PAGE SIZE SET FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setDefaultPageSize(pageSizes);
    console.log("current", page, pageSizes);
    let list = await dispatch(getIsbnData(page, pageSizes));
    if (list) {
      setIsbnList(list);
    }
  };
  //EDIT API
  const editIsbn = (value) => {
    setUpdateId(value._id);
    setIsModalOpen(true);
    // console.log("🚀 ~ file: seasons.js ~ line 66 ~ editIsbn ~ abc", value);
    form.setFieldsValue(value);
  };

  const columns = [
    {
      title: "ISBN Number",
      dataIndex: "isbnNumber",
      render: (value) => {
        return <span>{value && value ? value : "-"}</span>;
      },
    },    
    {
      title: "Action",
      dataIndex: "Action",
      render: (value, record) => {
        return (
          <>
            <a
              className="btn-icon"
              onClick={() => editIsbn(record)}
              to="#"
              shape="circle"
            >
              <FeatherIcon icon="edit" size={16} />
            </a>

            <Popconfirm
              title="Are you sure to delete this ISBN?"
              onConfirm={() => isbnDelete(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">
                {" "}
                <FeatherIcon size={14} icon="trash-2" />
              </a>
            </Popconfirm>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Main className="button-example">
        <Cards
          title="ISBN List"
          isbutton={
            <div className="card-radio">
              <Button htmlType="submit" type="primary" onClick={showModal}>
                Add ISBN
              </Button>
            </div>
          }
        >
          <Table
            dataSource={isbnList?.data}
            columns={columns}
            pagination={{
              showSizeChanger: true,
              pageSize: defaultPageSize,
              total: isbnList?.options?.totalDocs,
              onChange: onPageChange,
              pageSizeOptions: ["10", "50", "100", "500", "1000"],
            }}
          />
        </Cards>

        {/* ----create seasons MODAL--------- */}
        <Modal
          title={
            setUpdateIdRef.current !== "" ? "Edit ISBN" : "Add ISBN"
          }
          open={isModalOpen}
          onOk={form.submit}
          onCancel={handleCancel}
          okText="Submit"
        >
          <Form layout="vertical" onFinish={onSubmit} form={form}>
            <Row gutter={20}>
              <Col md={24} xs={24}>
                <Form.Item
                  id="isbnNumber"
                  name="isbnNumber"
                  label="ISBN number"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              {/* <Col md={24} xs={24}>
                <Form.Item
                  id="status"
                  name="status"
                  label="Status"
                  initialValue={"active"}
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select a status">
                    <Select.Option value="active">Active</Select.Option>
                    <Select.Option value="inactive">Inactive</Select.Option>
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default Isbn;
