/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API = {
  auth: {
    login: "/admin/login",
    signUp: "users/signup",
    changePassword: "admin/change-password",
  },
  seasons: {
    seasonsCreate: "/admin/seasons",
  },
  poems: {
    poemsLists: "/admin/poems",
  },
  artwork: {
    getArtwork: "/admin/artworks",
  },
  meditations: {
    meditationsGet: "/admin/meditations",
  },
  journals: {
    getJournals: "/admin/journals",
  },
  statics: {
    getStatic: "/admin/privacyTermDevotional",
  },
  staticUrl: {
    getUrl: "/static",
  },
  user: {
    getUser: "/admin/user-list",
    blockUser: "/admin/blockUser",
  },
  isbn: {
    isbnCreate: "/admin/isbn",
  },
  dashboard: {
    count: "/admin/dashboardData",
  },
  store: {
    getStore: "/admin/giftforbuy",
  },
};

export { API };
