import React, { useState } from "react";
import { Avatar, Modal, Form, Input  } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { InfoWraper, UserDropDwon } from "./auth-info-style";
import { Popover } from "../../popup/popup";
import { logOut, changePassword } from "../../../redux/authentication/actionCreator";
import Heading from "../../heading/heading";
import { BasicFormWrapper } from "../../../container/styled";

const AuthInfo = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [modalVisible, setModelVisible] = useState(false);

  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut());
  };

  const ChangePasswordModal = () => {
    setModelVisible(true);
  };

  const onSubmit = async (values) => {
    const payload = {
      password: values.password,
      oldPassword:values.oldPassword
    }
    await dispatch(changePassword(payload));
    setModelVisible(false);
    form.resetFields();
  }

  const handleCancel = (e) => {
    setModelVisible(false);
    form.resetFields();
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img
            src={require("../../../static/img/avatar/chat-auth.png")}
            alt=""
          />
          <figcaption>
            <Heading as="h5">Admin</Heading>
          </figcaption>
        </figure>
        <Link className="user-dropdwon__bottomAction" onClick={() => ChangePasswordModal()} to="#">
            <FeatherIcon icon="lock" /> Change password
        </Link>
        <Link className={modalVisible?"user-dropdwon__onAction":"user-dropdwon__downAction"} onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper style={{ padding: "0" }}>
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <div className="right_profile">
              <p>Super Admin</p>
              <Link to="#" className="head-example">
                <Avatar src={require("../../../static/img/avatar/profile-admin")}/>
              </Link>
          </div>
        </Popover>
      </div>

      <Modal
          title="Change Password"
          visible={modalVisible}
          okText="Submit"
          onOk={form.submit}
          onCancel={handleCancel}
          width={600}
        >
          <BasicFormWrapper>
            <Form form={form} name="add_organizer" onFinish={onSubmit}>
              <Form.Item
                label="Old Password"
                name="oldPassword"
                rules={[{ message: "Old Password is required", required: true }]}
              >
                <Input placeholder="Old Password" />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="password"
                rules={[{ message: "New Password is required", required: true }]}
              >
                <Input placeholder="New Password" />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirm_password"
                rules={[
                  {
                    required: true,
                    message: 'confirm password is required!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('confirm password needs to match!');
                    },
                  }),
                ]}
              >
                <Input placeholder="Confirm Password" />
              </Form.Item>
            </Form>
          </BasicFormWrapper>
        </Modal>

    </InfoWraper>

  );

  
};

export default AuthInfo;
