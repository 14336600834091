import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");


export const getArtwork = (page, limit) => {
  return async (dispatch) => {
    const resp = await DataService.get(
      API.artwork.getArtwork + "?page=" + page + "&limit=" + limit
    );
    if (!resp.data.error) {
      return resp.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const artWorkAdd = (value) => {
  console.log("valuevaluevaluevalue", value);
  return async (dispatch) => {
    const resp = await DataService.post(API.artwork.getArtwork, value);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
export const artWorkUpdate = (id, value) => {
  return async (dispatch) => {
    const resp = await DataService.put(
      API.artwork.getArtwork + "/" + id,
      value
    );
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};

export const deleteLists = (id) => {
  return async (dispatch) => {
    const resp = await DataService.delete(API.artwork.getArtwork + "/" + id);
    if (!resp.data.error) {
      return resp.data.data;
    } else {
      message.error(resp.data.message);
    }
  };
};
