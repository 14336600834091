import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Modal,
  Table,
  Popconfirm,
  Image,
} from "antd";
import { Main } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Button } from "../../components/buttons/buttons";
import {
  meditationsAdd,
  getMeditations,
  deleteLists,
  meditationsUpdate,
} from "../../redux/meditations/actionCreator";
import { getUrlData } from "redux/staticpage/actionCreator";
import useStateRef from "react-usestateref";
import { DeleteFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import FormItem from "antd/es/form/FormItem";
import ReactAudioPlayer from "react-audio-player";

const Meditations = () => {
  const [form] = Form.useForm();
  const { audioUrl } = useSelector((state) => state.static);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [meditations, setMeditations] = useState([]);
  const [updateId, setUpdateId, setUpdateIdRef] = useStateRef("");
  const [file, setFile] = useState("");
  const [audioUpload, setAudioUpload] = useState("");

  const dispatch = useDispatch();

  const [defaultPageSize, setDefaultPageSize] = useState(10);

  useEffect(() => {
    onPageChange(1, 10);
    const getCall = async () => {
      const res = await dispatch(getUrlData());
    };
    getCall();
  }, []);

  console.log("audio URL=", audioUrl);

  // audio upload ---------
  const changeHandler = (e) => {
    setAudioUpload(e.target.files[0]);
    setFile(
      <div className="Priv_imgs">
        <ReactAudioPlayer
          src={URL.createObjectURL(e.target.files[0])}
          controls
        />
        <div className="remove_img" onClick={removeAudio}>
          <DeleteFilled style={{ fontSize: "15px", color: "#fff" }} />
        </div>
      </div>
    );
  };
  const removeAudio = () => {
    setFile("");
  };

  const showModal = () => {
    setIsModalOpen(true);
    setAudioUpload("");
    setFile("");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setAudioUpload("");
    setFile("");
    form.resetFields();
    setUpdateId("");
  };

  //CREATE API
  const onSubmit = async (value) => {
    delete value.audio;
    var form_data = new FormData();
    form_data.append("audio", audioUpload);
    for (var key in value) {
      form_data.append(key, value[key]);
    }
    for (const pair of form_data.entries()) {
      // console.log(pair, "pair");
    }
    if (setUpdateIdRef.current !== "") {
      let Update = await dispatch(
        meditationsUpdate(setUpdateIdRef.current, form_data)
      );

      if (Update) {
        onPageChange(1, 10);
        setIsModalOpen(false);
        setUpdateId("");
      }
    } else {
      let create = await dispatch(meditationsAdd(form_data));

      if (create) {
        onPageChange(1, 10);
        setIsModalOpen(false);
        setAudioUpload("");
        setFile("");
      }
    }
    form.resetFields();
  };

  //DELETE API
  const artWorkDelete = async (id) => {
    const deleted = await dispatch(deleteLists(id));
    if (deleted) {
      onPageChange(1, 10);
    }
  };
  //PAGE SIZE SET FUNCTION CALL
  const onPageChange = async (page, pageSizes) => {
    setDefaultPageSize(pageSizes);
    console.log("current", page, pageSizes);
    let list = await dispatch(getMeditations(page, pageSizes));

    if (list) {
      setMeditations(list);
    }
  };
  //EDIT API
  const editArtwork = (value) => {
    setAudioUpload(audioUrl + value.audio);
    setFile(
      <div className="Priv_img">
        <ReactAudioPlayer src={audioUrl + value.audio} controls />
        <div className="remove_img" onClick={removeAudio}>
          <DeleteFilled style={{ fontSize: "15px", color: "#fff" }} />
        </div>
      </div>
    );
    form.setFieldsValue({ audio: value.audio });
    setUpdateId(value._id);
    setIsModalOpen(true);
    form.setFieldsValue(value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (value) => {
        return <span>{value && value ? value : "-"}</span>;
      },
    },
    {
      title: "Meditation Track",
      render: (value) => {
        return (
          <span>
            <>
              {audioUrl && audioUrl ? (
                <ReactAudioPlayer src={audioUrl + value.audio} controls />
              ) : (
                "-"
              )}
            </>
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (value, record) => {
        return (
          <>
            <a
              className="btn-icon"
              onClick={() => editArtwork(record)}
              to="#"
              shape="circle"
            >
              <FeatherIcon icon="edit" size={16} />
            </a>

            <Popconfirm
              title="Are you sure to delete this track?"
              onConfirm={() => artWorkDelete(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">
                {" "}
                <FeatherIcon size={14} icon="trash-2" />
              </a>
            </Popconfirm>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Main className="button-example">
        <Cards
          title="Meditations List"
          isbutton={
            <div className="card-radio">
              <Button htmlType="submit" type="primary" onClick={showModal}>
                Add Meditations
              </Button>
            </div>
          }
        >
          <Table
            dataSource={meditations.data}
            columns={columns}
            pagination={{
              showSizeChanger: true,
              pageSize: defaultPageSize,
              total: meditations?.options?.totalDocs,
              onChange: onPageChange,
              pageSizeOptions: ["10", "50", "100", "500", "1000"],
            }}
          />
        </Cards>

        {/* ----create seasons MODAL--------- */}
        <Modal
          title={
            setUpdateIdRef.current !== "" ? "Edit Meditation" : "Add Meditation"
          }
          open={isModalOpen}
          onOk={form.submit}
          onCancel={handleCancel}
          okText="Submit"
        >
          <Form layout="vertical" onFinish={onSubmit} form={form}>
            <Row gutter={20}>
              <Col md={24} xs={24}>
                <Form.Item
                  id="name"
                  name="name"
                  label="Name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col md={24} xs={24}>
                <Form.Item
                  name="audio"
                  label="Meditation Track"
                  rules={[{ required: true }]}
                >
                  {file ? (
                    <div>{file}</div>
                  ) : (
                    <div className="Upload_product">
                      <Input
                        type="file"
                        title=""
                        accept="audio/*"
                        onChange={changeHandler}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default Meditations;
