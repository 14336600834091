import actions from "./actions";

const {
  GET_TERMS,
  UPDATE_TERMS,
  GET_URL,
  UPDATE_POLICY,
  GET_DEVOTION,
  UPDATE_DEVOTION,
} = actions;

const initState = {
  loading: false,
  terms: null,
  artWorksUrl: null,
  soundsUrl: null,
  audioUrl: null,
  seasons: null,
  giftForBuyImage: null,
};

const staticReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_TERMS:
      console.log(data?.data?.data);
      return {
        ...state,
        loading: true,
        terms: data?.data?.data?.content,
      };

    case GET_URL:
      console.log(data?.data?.data, "url data");
      return {
        ...state,
        loading: true,
        artWorksUrl: data?.data?.data?.artWorksUrl,
        audioUrl: data?.data?.data?.audioUrl,
        soundsUrl: data?.data?.data?.soundsUrl,
        seasons: data?.data?.data?.seasons,
        giftForBuyImage: data?.data?.data?.giftForBuyImage,
      };

    case UPDATE_TERMS:
      // console.log(data?.data?.data);
      return {
        ...state,
        loading: true,
        // terms: data?.data?.data?.docs,
      };

    default:
      return state;
  }
};
export default staticReducer;
